import { differenceInMinutes, differenceInSeconds, parseISO } from "date-fns"

import { GPSInfo, VehicleData } from "types/vehicle"

function getBestGps(vehicle: VehicleData): {
  gps: GPSInfo
  source: "primary" | "secondary"
  stale: boolean
} | null {
  const primaryGps = vehicle?.gps
  const secondaryGps = vehicle?.secondaryGps
  let gps: GPSInfo
  let source: "primary" | "secondary"
  if (primaryGps != null && secondaryGps == null) {
    gps = primaryGps
    source = "primary"
  } else if (primaryGps == null && secondaryGps != null) {
    gps = secondaryGps
    source = "secondary"
  } else if (primaryGps != null && secondaryGps != null) {
    const diff = differenceInSeconds(
      parseISO(secondaryGps.lastUpdated),
      parseISO(primaryGps.lastUpdated)
    )

    if (diff > 10) {
      gps = secondaryGps
      source = "secondary"
    } else {
      gps = primaryGps
      source = "primary"
    }
  } else {
    return null
  }
  const stale = differenceInMinutes(new Date(), parseISO(gps.lastUpdated)) > 5
  return { gps: gps, source: source, stale: stale }
}

export { getBestGps }
