import { useEffect, useState } from "react"

import { getQueryString } from "./fetch-utils"

function getUrlParamsAsObject(urlStringParams) {
  const urlParams = new URLSearchParams(urlStringParams)
  const entries = urlParams.entries()
  const result = {}
  for (const entry of entries) {
    const [key, value] = entry
    result[key] = value
  }
  return result
}

// Starts with null value then set to blank string if query string empty
// Allows you to see when query string has been loaded
export const useQueryString = () => {
  const [queryString, setQueryString] = useState<string | null>(null)
  useEffect(() => {
    if (queryString !== window.location.search) {
      setQueryString(window.location.search)
    }
  })
  return { queryString }
}

export function updateQueryString(
  oldString: string,
  newParams: Record<string, unknown>
) {
  // Build an updated string of query string parameters, combining new and old.
  const queryObject = getUrlParamsAsObject(oldString)
  for (const key in newParams) {
    queryObject[key] = newParams[key]
    if (!newParams[key]) {
      delete queryObject[key]
    }
  }
  const parameters = new URLSearchParams(queryObject).toString()
  let queryString: string
  if (parameters == "") {
    queryString = ""
  } else {
    queryString = `?${parameters}`
  }
  return {
    queryObject: queryObject,
    queryString: queryString,
  }
}

type UrlParam = {
  tag: string
  value: string
}

export function updateQueryParam(
  currentQueryString: string,
  newParam: UrlParam
) {
  const params = getUrlParamsAsObject(currentQueryString)
  params[newParam.tag] = newParam.value
  const updatedQueryString = updateQueryString(window.location.search, params)
  if (updatedQueryString.queryString !== currentQueryString) {
    window.history.pushState(
      null,
      document.title,
      `${window.location.href.split("?")[0]}${updatedQueryString.queryString}`
    )
  }
}

export function setQueryString(newParams) {
  // Replace the current query string parameters with new ones
  const updated = `?${new URLSearchParams(newParams).toString()}`
  window.history.pushState(
    null,
    document.title,
    `${window.location.href.split("?")[0]}${updated}`
  )
}

export function removeQueryStringParams(oldString, paramsToRemove) {
  const queryObject = getUrlParamsAsObject(oldString)
  for (const key in paramsToRemove) {
    delete queryObject[paramsToRemove[key]]
  }
  return `?${new URLSearchParams(queryObject).toString()}`
}

type UrlParamConfig = {
  tag: string
  setState: (value: any) => void
  default?: any
}

export const updateStateFromUrl = (
  currentQueryString: string,
  paramsList: Array<UrlParamConfig>
) => {
  const path = new URLSearchParams(currentQueryString)
  paramsList.forEach((param) => {
    if (path.has(param.tag)) {
      param.setState(path.get(param.tag))
    } else if (param.default) {
      updateQueryParam(currentQueryString, {
        tag: param.tag,
        value: param.default,
      })
    }
  })
}

export function getGoogleMapLink(
  latitude: number,
  longitude: number,
  googlePlaceId?: string
) {
  const query = getQueryString({
    api: 1,
    query: `${latitude},${longitude}`,
    query_place_id: googlePlaceId,
  })
  return "https://www.google.com/maps/search/" + query
}
