// @ts-strict-ignore
import { graphql, useStaticQuery } from "gatsby"

const useRouteMapData = () => {
  // @ts-expect-error: the CI build is missing `gatsby-types.d.ts`
  // See https://github.com/gatsbyjs/gatsby/discussions/36220
  const data: Queries.routeMapDataQuery = useStaticQuery(graphql`
    query routeMapData {
      allServiceYaml {
        nodes {
          serviceId
          origin
          destination
          routes {
            title
            to
            outbound {
              serviceNumber
              stopPattern {
                stop {
                  stopId
                  name
                  stopName
                  description
                  regionName
                  coordinate
                  googlePlaceId
                  atcoCode
                }
                dropOffOnly
                preBooked
                cutOffMins
                viaPartnerConnection
                description
                routeToNextStopVersion
              }
            }
            return {
              serviceNumber
              stopPattern {
                stop {
                  stopId
                  name
                  stopName
                  description
                  regionName
                  coordinate
                  googlePlaceId
                  atcoCode
                }
                dropOffOnly
                preBooked
                cutOffMins
                viaPartnerConnection
                description
                routeToNextStopVersion
              }
            }
          }
        }
      }
      allPathYaml {
        nodes {
          origin {
            stopId
          }
          destination {
            stopId
          }
          version
          coordinates
        }
      }
    }
  `)

  return data
}

export { useRouteMapData }
