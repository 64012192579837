// @ts-strict-ignore
import React from "react"

import { isAfter } from "date-fns"
import { Form, Formik } from "formik"
import styled, { css } from "styled-components"

import { Text } from "@kiwicom/orbit-components"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import { EmberSelectOption } from "components/generic/ember-select"
import { EmberFormSelect } from "components/generic/formik-inputs"
import { ListTitleProps } from "components/generic/list-title"
import Layout from "components/layout"
import { RouteMap, RouteMapProps } from "components/route-map"

const InfoPanel = styled.div`
  position: relative;
  background: #ffffff;
  padding: 15px 15px;
  bottom: 0px;
  border-top: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  ${media.mediumMobile(css`
    position: absolute;
    top: 60px;
    bottom: unset;
    margin: 10px;
    width: 400px;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.orbit.borderColorCard};
    border: 0;
    box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
  `)}
`

const FullPageWrapper = styled.div`
  height: 300px;
`

const RouteMapWrapper = styled.div`
  flex: 1;
  display: flex;
`

// Messy but will be removed in future once routes are moved to back-end
// E10 launch date can be removed post January 11th

const RouteOptions: (EmberSelectOption | ListTitleProps)[] = [
  {
    id: "all-routes",
    title: "All Routes",
  },
  { type: "list-title", title: "Route E1" },
  {
    id: "aberdeen-to-edinburgh-via-dundee-outbound",
    title: "Dundee to Edinburgh",
  },
  {
    id: "aberdeen-to-edinburgh-via-dundee-return",
    title: "Edinburgh to Dundee",
  },
  { type: "list-title", title: "Route E3" },
  {
    id: "dundee-to-glasgow-outbound",
    title: "Dundee to Glasgow",
  },
  {
    id: "dundee-to-glasgow-return",
    title: "Glasgow to Dundee",
  },
  {
    type: "list-title",
    title: "Route E10",
  },
  {
    id: "msip-to-dundee-city-centre-return",
    title: "Dundee City Centre to Dundee East",
  },
  {
    id: "msip-to-dundee-city-centre-outbound",
    title: "Dundee East to Dundee City Centre",
  },
  {
    type: "list-title",
    title: `Route E1X ${
      isAfter(new Date(), new Date(2024, 4, 14))
        ? ""
        : "(Launching 14 May 2024)"
    }`,
  },
  {
    id: "dundee-to-wallyford-via-edinburgh-outbound",
    title: "Dundee to Wallyford (via Edinburgh)",
  },
  {
    id: "dundee-to-wallyford-via-edinburgh-return",
    title: "Wallyford to Dundee (via Edinburgh)",
  },
  {
    type: "list-title",
    title: `Route E3X ${
      isAfter(new Date(), new Date(2024, 4, 7)) ? "" : "(Launching 7 May 2024)"
    }`,
  },
  {
    id: "dundee-to-glasgow-via-perth-outbound",
    title: "Dundee & Perth to Glasgow",
  },
  {
    id: "dundee-to-glasgow-via-perth-return",
    title: "Glasgow to Dundee & Perth",
  },
]

function getIdAndDirection(
  routeAndDirection: EmberSelectOption
): RouteMapProps["showService"] {
  let id:
    | "aberdeen-to-edinburgh-via-dundee"
    | "dundee-to-glasgow"
    | "msip-to-dundee-city-centre"
    | "dundee-to-wallyford-via-edinburgh"
    | "dundee-to-glasgow-via-perth"
  let direction: "outbound" | "return"
  if (routeAndDirection.id == "all-routes") {
    return "all"
  } else if (
    routeAndDirection.id == "aberdeen-to-edinburgh-via-dundee-outbound"
  ) {
    id = "aberdeen-to-edinburgh-via-dundee"
    direction = "outbound"
  } else if (
    routeAndDirection.id == "aberdeen-to-edinburgh-via-dundee-return"
  ) {
    id = "aberdeen-to-edinburgh-via-dundee"
    direction = "return"
  } else if (routeAndDirection.id == "dundee-to-glasgow-outbound") {
    id = "dundee-to-glasgow"
    direction = "outbound"
  } else if (routeAndDirection.id == "dundee-to-glasgow-return") {
    id = "dundee-to-glasgow"
    direction = "return"
  } else if (routeAndDirection.id == "msip-to-dundee-city-centre-outbound") {
    id = "msip-to-dundee-city-centre"
    direction = "outbound"
  } else if (routeAndDirection.id == "msip-to-dundee-city-centre-return") {
    id = "msip-to-dundee-city-centre"
    direction = "return"
  } else if (
    routeAndDirection.id == "dundee-to-wallyford-via-edinburgh-outbound"
  ) {
    id = "dundee-to-wallyford-via-edinburgh"
    direction = "outbound"
  } else if (
    routeAndDirection.id == "dundee-to-wallyford-via-edinburgh-return"
  ) {
    id = "dundee-to-wallyford-via-edinburgh"
    direction = "return"
  } else if (routeAndDirection.id == "dundee-to-glasgow-via-perth-outbound") {
    id = "dundee-to-glasgow-via-perth"
    direction = "outbound"
  } else if (routeAndDirection.id == "dundee-to-glasgow-via-perth-return") {
    id = "dundee-to-glasgow-via-perth"
    direction = "return"
  } else {
    id = null
    direction = null
  }
  return { id: id, direction: direction }
}

export default () => {
  return (
    <FullPageWrapper>
      <Layout
        title="Map"
        description="Track your bus, check out our route map and find out exactly where we stop"
        includeFooter={false}
      >
        <Formik
          initialValues={{
            routeAndDirection: RouteOptions[0],
          }}
          onSubmit={() => null}
        >
          {({ values }) => (
            <Form style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <RouteMapWrapper>
                <RouteMap
                  showService={getIdAndDirection(
                    values.routeAndDirection as EmberSelectOption
                  )}
                  showVehicles
                  padding={100}
                />
              </RouteMapWrapper>
              <InfoPanel>
                <Stack spacing="XSmall" direction="column">
                  <Stack direction="row" justify="between">
                    <Text weight="bold">Choose Route and Direction</Text>
                  </Stack>
                  <EmberFormSelect
                    name="routeAndDirection"
                    options={RouteOptions}
                    customMaxHeightDesktop="70vh"
                    customMaxHeightMobile="90vh"
                  />
                </Stack>
              </InfoPanel>
            </Form>
          )}
        </Formik>
      </Layout>
    </FullPageWrapper>
  )
}
