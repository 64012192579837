import * as Sentry from "@sentry/gatsby"
import moment from "moment"

import { PathType, ServiceType, StopPatternType } from "types/gatsby"

function classifyStop(stop: StopPatternType) {
  if (
    stop.preBooked == true ||
    stop.dropOffOnly == true ||
    stop.viaPartnerConnection == true
  ) {
    return "secondary"
  } else {
    return "primary"
  }
}

function getServiceData(
  service: ServiceType,
  allPaths: readonly PathType[],
  direction: "outbound" | "return",
  as_of: string
): { stopPatternItems: readonly StopPatternType[]; fullRoute: number[][] } {
  let stopPatternItems: readonly StopPatternType[]
  const fullRoute: number[][] = []

  if (!service) {
    stopPatternItems = []
  } else {
    const activeRoutes = service.routes.find((route) => {
      return route.to == null || moment(as_of) < moment(route.to)
    })
    if (activeRoutes) {
      if (direction == "outbound") {
        stopPatternItems = activeRoutes.outbound.stopPattern
      } else if (direction == "return") {
        stopPatternItems = activeRoutes.return.stopPattern
      } else {
        stopPatternItems = [] // Should not happen
      }
    } else {
      stopPatternItems = []
    }

    const primaryStopPatternItems = stopPatternItems.filter(
      (stop) => classifyStop(stop) == "primary"
    )

    // Trying to handle strange error that occurs occassionally. Haven't been able to understand why
    // primaryStopPatternItems would not be an array
    if (
      primaryStopPatternItems != null &&
      typeof primaryStopPatternItems.entries === "function"
    ) {
      for (const [
        index,
        stopPatternItem,
      ] of primaryStopPatternItems.entries()) {
        const stop = stopPatternItem.stop
        fullRoute.push(stop.coordinate as number[])
        const nextStopPatternItem = primaryStopPatternItems[index + 1]
        if (nextStopPatternItem) {
          const nextStop = nextStopPatternItem.stop
          const routeToNextStop = allPaths.find(
            (x) =>
              x.origin.stopId === stop.stopId &&
              x.destination.stopId === nextStop.stopId &&
              (x.version === stopPatternItem.routeToNextStopVersion ||
                stopPatternItem.routeToNextStopVersion == null)
          )
          if (routeToNextStop) {
            fullRoute.push(...(routeToNextStop.coordinates as number[][]))
          }
        }
      }
    } else {
      Sentry.captureException(
        new Error(
          "Primary stop pattern items was not an array when getting service data"
        ),
        {
          extra: {
            "Primary Stop Pattern Items": primaryStopPatternItems,
          },
        }
      )
    }
  }

  return { stopPatternItems: stopPatternItems, fullRoute: fullRoute }
}

export { getServiceData, classifyStop }
